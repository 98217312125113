

































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class MemberAddEdit extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public member: any = {};
  public organisation: any = null;
  public organisationOptions: any = [];

  public async created() {
    this.loadOrganisations();
    this.refresh();
  }

  private loadOrganisations() {
    this.$store.dispatch('loadOrganisations', {
      limit: 500,
      sort_by: 'name'})
    .then((response: any) => {
      this.organisationOptions = response.organisations;
    }).catch((error: any) => {
      this.globalError(error);
    });
  }

  @Watch('$route')
  private refresh() {
    this.loading = true;
    if (this.id !== null) {
      this.$store.dispatch('loadUserById', {
        userId: this.id,
      }).then((response: any) => {
        this.member = response;
        this.organisation = response.organisation;
        this.loading = false;
      }).catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
    } else {
      this.loading = false;
    }
  }

  private onSubmit() {
    this.loading = true;

    const data: any = {
      payload: {
        firstname: this.member.firstname,
        lastname: this.member.lastname,
        mobile: this.member.mobile,
        email: this.member.email,
        organisation_id: this.organisation ? this.organisation.id : null,
      },
    };
    let name = 'editUser';
    let method = 'edité';
    if (this.id === null) {
      name = 'addUser';
      method = 'ajouté';

      if (this.organisation === null) {
        this.$q.notify('Une organisation est nécessaire');
        return;
      }

    } else {
      data.userId = this.id;
    }

    this.$store.dispatch(name, data)
    .then((response: any) => {
      this.$q.notify({
          message: `${response.firstname} ${response.lastname} a été ${method}`,
          color: 'teal',
        });
      this.$router.push(`/admin/member/${response.id}`);
      this.member = response;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }
}
